import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button } from 'react-bootstrap';
import {
  Category,
  ModalScreenProps,
  RemoveDuplicateObjectProps,
  ForProAppProps,
  Template,
} from '../types/labor_consultations';
import {
  Categories,
  Label,
  LaunchButton,
  RadioButtons,
  Templates,
  Textfield,
  WordInput,
  AttachmentFile,
} from '../components/labor_consultations';
import { requestApi } from '../shared/requests';
import { handleError } from '../shared/common';
import { useDelayedEffect } from '../lib/use_delayed_effect';

const queryTemplatesWithWords = (
  word: string, categories: Category[], categoryName: string,
): Template[] => {
  let templates: Template[] = [];
  // stringとregexが混ざってしまっているが、ひとまずanyで対応
  // TODO(okubo): 急ぎではないが、時間があれば型を直す
  // eslint-disable-next-line
  let matcha: any = '';

  if (word.length === 0) {
    return null;
  }

  // 改行を許可するためのスペースなのでlintどがいし
  // eslint-disable-next-line no-irregular-whitespace
  const modifiedWord = word.replace(/　/g, ' ').split(' ');

  // arrayの長さが1であれば一つだけで検索
  if (modifiedWord.length === 1) {
    matcha = new RegExp(modifiedWord[0]);
  } else {
    // arrayの長さが1以上であれば、複数ワードの検索を行う
    for (let i = 0; modifiedWord.length > i; i += 1) {
      if (matcha.length === 0) {
        matcha = `(?=.+${modifiedWord[i]})`;
      } else {
        matcha = `${matcha}(?=.+${modifiedWord[i]})`;
      }
    }
    matcha = new RegExp(matcha);
  }

  // カテゴリが選択されていないので、全てのカテゴリから合致する項目を検索
  if (categoryName === '') {
    for (let i = 0; categories.length > i; i += 1) {
      for (let j = 0; categories[i].templates.length > j; j += 1) {
        if (matcha.test(categories[i].templates[j].title)
          || matcha.test(categories[i].templates[j].content)) {
          templates = templates.concat(categories[i].templates[j]);
        }
      }
    }
  } else {
    // カテゴリが選択されている場合はそのカテゴリ内を検索
    const category = categories.find((c) => c.name === categoryName);
    for (let i = 0; category.templates.length > i; i += 1) {
      if (matcha.test(category.templates[i].title) || matcha.test(category.templates[i].content)) {
        templates = templates.concat(category.templates[i]);
      }
    }
  }
  return templates;
};

const removeCircleMark = (text: string): string => {
  const matcha = new RegExp('●');
  if (matcha.test(text)) {
    return text.replace('●', '');
  }
  return text;
};

const removeDuplicates = (array: Template[]): Template[] | null => {
  if (array.length === 0) {
    return null;
  }

  const newArray: Template[] = [];
  const lookupObject: RemoveDuplicateObjectProps = {} as RemoveDuplicateObjectProps;
  let i: string; // TODO(okubo): iでstringは紛らわしいので時間できたら修正する
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (i in array) {
    lookupObject[array[i].title] = array[i];
  }

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
};

const getDataFromApi = async (url: string): Promise<Category[]> => {
  const res = await requestApi({}, 'get', url);
  const categoryData: Category[] = [];
  for (let i = 0; res.data.length > i; i += 1) {
    categoryData.push(JSON.parse(res.data[i]));
  }
  return categoryData;
};

const ModalScreen: React.FC<ModalScreenProps> = (
  {
    isProUser, show, handleModalClose, token,
  },
): JSX.Element => {
  // radio buttonで自分のテンプレートから検索するかどうかのbool
  const [myTemplatesFlag, setMyTemplatesFlag] = useState<boolean>(false);
  // APIから読み込まれたbaseカテゴリ
  const [baseCategories, setBaseCategories] = useState<Category[]>([]);
  // APIから自社のテンプレートカテゴリ
  const [myBaseCategories, setMyBaseCategories] = useState<Category[]>([]);
  // 「カテゴリで絞り込む」に表示されるカテゴリ
  const [categories, setCategories] = useState<Category[]>([]);
  // 選択中のカテゴリ名を保持
  const [categoryName, setCategoryName] = useState<string>('');
  // 「テンプレートを選択」に表示されるテンプレ
  const [templates, setTemplates] = useState<Template[]>([]);
  // 選択中のテンプレ
  const [selectedTemplate, setSelectedTemplate] = useState<Template>({} as Template);
  // 「ワードで検索」のワード
  const [word, setWord] = useState<string>('');
  // 添付ファイルを適用するかどうか
  const [insertFile, setInsertFile] = useState<boolean>(true);
  // downloadのリンク
  const [downloadLink, setDownloadLink] = useState<string>('');
  // 検索確定ワード
  const [confirmedText, setConfirmedText] = useState<string>('');

  const createLog = (type: string, data: string): void => {
    const params = {
      authenticity_token: token,
      operation_log: {
        operation_type: type,
        relation_id: type === '2' ? data : null,
        data: type === '1' ? data : null,
      },
    };
    requestApi(params, 'post', '/consultants/operation_logs')
      // eslint-disable-next-line no-restricted-globals,no-return-assign
      .then()
      .catch((error) => handleError(error.response.data.message));
  };

  // 検索ワード遅延取得
  useDelayedEffect(
    () => {
      setConfirmedText(word);
    },
    [word],
    1500,
  );

  if (confirmedText !== '') {
    createLog('1', confirmedText);
  }

  useEffect(() => {
    let unmounted = false;
    const f = async () => {
      let myCategoryData: Category[] = [];
      const baseCategoryData: Category[] = await getDataFromApi('/labor_consultation_templates');
      if (isProUser) {
        myCategoryData = await getDataFromApi('/consultants/labor_consultation_templates/my_templates');
      }

      if (!unmounted) {
        setBaseCategories(baseCategoryData);
        setMyBaseCategories(myCategoryData);
        setCategories(baseCategoryData);
      }
    };
    f();
    return () => { unmounted = true; };
  }, []);

  const myTemplatesOnChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const showMyTemplates: boolean = e.target.value.toLowerCase() === 'true';
    setMyTemplatesFlag(showMyTemplates);
    if (showMyTemplates) {
      setCategories(myBaseCategories);
      setCategoryName('');
      setTemplates([]);
      setSelectedTemplate({} as Template);
    } else {
      setCategories(baseCategories);
      setCategoryName('');
      setTemplates([]);
      setSelectedTemplate({} as Template);
    }
  };

  const convertImageSrcToDownloadableUrl = (url: string): void => {
    if (url === null || url === '') {
      return;
    }
    const splitedUrl = url.split('/');
    setDownloadLink(`https://drive.google.com/uc?export=download&id=${splitedUrl[5]}`);
  };

  const categoryOnChanged = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const category: Category = categories.find((c) => c.name === e.target.value);
    setCategoryName(category?.name ? category?.name : '');

    const queriedTemplates: Template[] = queryTemplatesWithWords(word, categories, categoryName);
    if (queriedTemplates?.length >= 1) {
      setTemplates(removeDuplicates(queriedTemplates));
      setSelectedTemplate(queriedTemplates[0]);
    } else {
      setTemplates(removeDuplicates(category?.templates ? category?.templates : []));
      setSelectedTemplate(category?.templates[0]);
      convertImageSrcToDownloadableUrl(category?.templates[0].attachment_url);
    }
  };

  const templateOnChanged = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const title: string = removeCircleMark(e.target.value);
    const template: Template = templates.find((t) => t.title === title);
    convertImageSrcToDownloadableUrl(template.attachment_url);
    setSelectedTemplate(template);
  };

  // stateの反映が遅いので一部e.target.valueで対応
  const wordOnChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setWord(e.target.value);
    const queriedTemplates: Template[] = queryTemplatesWithWords(
      e.target.value, categories, categoryName,
    );
    const category: Category = categories.find((c) => c.name === categoryName);

    if (e.target.value) {
      setTemplates(queriedTemplates);
      if (queriedTemplates?.length >= 1) {
        setSelectedTemplate(queriedTemplates[0]);
      } else {
        setSelectedTemplate({} as Template);
      }
    } else {
      setTemplates(category?.templates ? category?.templates : []);
      setSelectedTemplate(category?.templates[0]);
    }
  };

  const handleFileInsert = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!!e.target.checked === true) {
      setInsertFile(true);
    } else {
      setInsertFile(false);
    }
  };

  const insert = (): void => {
    const contentField = $('#contentField');
    setConfirmedText('');
    contentField.val(contentField.val() + selectedTemplate.content);
    // attachment_urlがない状態 === テンプレが設定されていない => ファイルをからの状態に移行させる
    if (insertFile === true && selectedTemplate.attachment_url !== null) {
      // ファイルを登録していたらクリア
      $('#labor_consultation_message_file').val(null);
      $('#labor_consultation_message_attachment_url').val(selectedTemplate.attachment_url);
      $('#labor_consultation_message_filename').val(selectedTemplate.filename);
      $('#selectFile').hide();
      $('.selected-file-name').text(selectedTemplate.filename);
    } else {
      $('#labor_consultation_message_attachment_url').val(null);
      $('#labor_consultation_message_filename').val(null);
      $('#selectFile').show();
      $('.selected-file-name').text('ファイルが選択されていません。');
    }
    setInsertFile(true);
    createLog('2', String(selectedTemplate.id));
    handleModalClose();
  };

  return (
    <Modal show={show} onHide={handleModalClose} className="labor-consultations-template-modal-dialog">
      <Modal.Header closeButton>
        <Modal.Title>テンプレート選択</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          {isProUser
            && (
              <>
                <Label name="テンプレート選択" />
                <RadioButtons showMyTemplate={myTemplatesFlag} onChange={myTemplatesOnChanged} />
              </>
            )}
          <Label name="ワードで検索" />
          <WordInput onChange={wordOnChanged} word={word} />
          <Label name="カテゴリで絞り込む" />
          <Categories
            categories={categories}
            onChange={categoryOnChanged}
            categoryName={categoryName}
          />
          <Label name="テンプレートを選択" />
          <Templates templates={templates} onChange={templateOnChanged} />
          <Label name="本文" />
          <Textfield text={selectedTemplate?.content} />
          {selectedTemplate?.attachment_url ? (
            <>
              <Label name="添付ファイル" />
              <span className="ml-2 text-danger">※添付ファイルを送信しない場合は、チェックを外してください。</span>
              <AttachmentFile
                filename={selectedTemplate.filename}
                value={insertFile}
                onChange={handleFileInsert}
                imageSrc={downloadLink}
              />
            </>
          )
            : (
              <>
                <Label name="添付ファイル" />
                <p>
                  添付ファイルはありません
                </p>
              </>
            )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={insert}>追加する</Button>
        <Button variant="secondary" onClick={handleModalClose}>閉じる</Button>
      </Modal.Footer>
    </Modal>
  );
};

const ForProApp: React.FC<ForProAppProps> = ({ token }): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);
  const handleClose = (): void => setShow(false);
  const handleShow = (): void => setShow(true);

  return (
    <>
      <LaunchButton handleModalShow={handleShow} />
      <ModalScreen isProUser show={show} handleModalClose={handleClose} token={token} />
    </>
  );
};

const ForFlucleApp: React.FC = (): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);
  const handleClose = (): void => setShow(false);
  const handleShow = (): void => setShow(true);

  return (
    <>
      <LaunchButton handleModalShow={handleShow} />
      <ModalScreen isProUser={false} show={show} handleModalClose={handleClose} token="" />
    </>
  );
};

$(() => {
  const elm = document.querySelector('#loanchTemplateButton');
  const token: string = JSON.parse(elm.getAttribute('token'));
  if (elm) {
    ReactDOM.render(
      <ForProApp
        token={token}
      />,
      elm,
    );
  }
});

$(() => {
  const elm = document.querySelector('#loanchTemplateButtonForFlucle');
  if (elm) {
    ReactDOM.render(
      <ForFlucleApp />,
      elm,
    );
  }
});
