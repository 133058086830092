import React from 'react';
import {
  AttachmentFileProps,
  CategoriesProps,
  LabelProps,
  LaunchButtonProps,
  RadioButtonsProps, TemplatesProps, TextfieldProps,
  WordInputProps,
  LinkProps,
} from '../types/labor_consultations';

export const LaunchButton: React.FC<LaunchButtonProps> = ({ handleModalShow }): JSX.Element => (
  <div className="my-auto" role="presentation" onClick={handleModalShow}>
    <i className="fas fa-list mr-1" />
    返信用テンプレートを使用
  </div>
);

export const Label: React.FC<LabelProps> = ({ name }): JSX.Element => (
  // TODO(okubo): labelとinputは同じ関数で扱うように編集する
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label>{name}</label>
);

export const Link: React.FC<LinkProps> = ({ href, text }): JSX.Element => {
  const linkStyles: React.CSSProperties = {
    color: 'blue',
    float: 'right',
    display: 'inline-block',
    borderBottom: '1px solid',
  };
  return <a style={linkStyles} href={href} target="_blank" rel="noopener noreferrer">{ text }</a>;
};

export const RadioButtons: React.FC<RadioButtonsProps> = ({
  showMyTemplate, onChange,
}): JSX.Element => (
  <div>
    <input type="radio" value={`${false}`} onChange={onChange} checked={showMyTemplate === false} />
    HRbaseテンプレート
    <input type="radio" value={`${true}`} onChange={onChange} checked={showMyTemplate === true} />
    自社テンプレート
    <Link text="テンプレートの追加リクエストができます" href="https://jp.surveymonkey.com/r/DYM7PPM" />
  </div>
);

export const WordInput: React.FC<WordInputProps> = ({ onChange, word }): JSX.Element => (
  <input id="wordInput" className="form-control" onChange={onChange} value={word} />
);

export const Categories: React.FC<CategoriesProps> = (
  { categories, categoryName, onChange },
): JSX.Element => (
  <select id="categoryInput" className="form-control" onChange={onChange} value={categoryName}>
    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
    <option />
    {
      categories?.map(
        (c, index) => <option key={index.toString()} value={c?.name}>{ c?.name }</option>,
      )
    }
  </select>
);

export const Templates: React.FC<TemplatesProps> = ({ templates, onChange }): JSX.Element => (
  <select id="templateList" className="set-height form-control" style={{ height: 160 }} size={templates?.length} onChange={onChange}>
    { templates?.map((c, index) => (
      <option data-icon="fas fa-paperclip" key={index.toString()}>
        { `${c.title}` }
      </option>
    )) }
  </select>
);

export const Textfield: React.FC<TextfieldProps> = ({ text }): JSX.Element => {
  const textFieldStyles: React.CSSProperties = {
    border: '1px solid #ced4da',
    padding: '10px',
    height: '270px',
    overflow: 'scroll',
    whiteSpace: 'pre-line',
  };
  return <div style={textFieldStyles}>{ text }</div>;
};

export const AttachmentFile: React.FC<AttachmentFileProps> = ({
  filename, onChange, value, imageSrc,
}): JSX.Element => (
  <div>
    <input
      type="checkbox"
      className="picked-company-checkbox my-auto"
      onChange={onChange}
      defaultChecked={value}
    />
    <span className="mx-3">{ filename }</span>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <a href={imageSrc} download={filename}>
      <i className="fas fa-download" />
    </a>
  </div>
);
